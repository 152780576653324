import axios from 'axios'
import Vue from 'vue'
import bus from "./utils/bus";
// import errorManager from "./utils/errorManager";
// import OfflineController from "./utils/OfflineControllerApi";


/**
 * @category Headers ::
 * ::::::::::::::::::::::::::::::::::::::::::::::::::::
 */
var config = {headers: {'Content-Type': 'application/json'}};
var patch = {headers: {'Content-Type': 'multipart/form-data'}};


Vue.prototype.$http = axios;

/**
 * @category Route ::
 * ::::::::::::::::::::::::::::::::::::::::::::::::::::
 */
 const baseUri = 'https://api.asistentemovistar.com.mx/api/';
//  const baseUri = 'http://apimovistar.test/api/';

var api = {

  baseUri: baseUri,

  baseUriFiles: "https://api.asistentemovistar.com.mx/uploads/",
  // baseUriFiles: "http://apimovistar.test/uploads/",


  loader: function (status) {
    bus.$emit("page_loader", status)
  },

  get: function (endpoint, params = null, show_lodader = false) {

    var url = new URL(baseUri + endpoint);

    if (params) {
      Object.keys(params).forEach(function (key) {
        url.searchParams.append(key, params[key]);
      });
    }

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve, reject) => {
      axios.get(url.href, config).then(
        response => {
          show_lodader ? api.loader(false) : null;
          resolve(response)
        },
        error => {

          console.log(error.toString());

          if (error.toString() === "Error: Network Error") {
            // OfflineController(api.get, arguments, resolve, reject);
            return;
          }

          show_lodader ? api.loader(false) : null;
          if (!error.response.data.errors) {
            // errorManager.showToast(
            //   "Ocurrió un error desconocido, vuelve a intentarlo mas tarde"
            // )
          } else {
            error.api = error.response.data.errors;
          }
          reject(error)
        }
      )
    });


  },
  post: function (endpoint, data, show_lodader = false) {

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve, reject) => {
      axios.post(baseUri + endpoint, data, config).then(
        response => {

          show_lodader ? api.loader(false) : null;
          resolve(response)
        },
        error => {

          if (error.toString() === "Error: Network Error") {
            // OfflineController(api.post, arguments, resolve, reject);
            return;
          }

          show_lodader ? api.loader(false) : null;
          if (!error.response.data.errors) {
            // errorManager.showToast(
            //   "Ocurrió un error desconocido, vuelve a intentarlo mas tarde"
            // )
          } else {
            error.api = error.response.data.errors;
          }
          reject(error)
        }
      )
    });


  },
  patch: function (endpoint, data, show_lodader = false) {

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve, reject) => {
      axios.patch(baseUri + endpoint, data, patch).then(
        response => {
          show_lodader ? api.loader(false) : null;
          resolve(response)
        },
        error => {

          if (error.toString() === "Error: Network Error") {
            // OfflineController(api.patch, arguments, resolve, reject);
            return;
          }

          show_lodader ? api.loader(false) : null;
          if (!error.response.data.errors) {
            // errorManager.showToast(
            //   "Ocurrió un error desconocido, vuelve a intentarlo mas tarde"
            // )
          } else {
            error.api = error.response.data.errors;
          }
          reject(error)
        }
      )
    });


  },
  put: function (endpoint, data, show_lodader = false) {

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve, reject) => {
      axios.put(baseUri + endpoint, data, config).then(
        response => {
          show_lodader ? api.loader(false) : null;
          resolve(response)

        },
        error => {

          if (error.toString() === "Error: Network Error") {
            // OfflineController(api.put, arguments, resolve, reject);
            return;
          }

          show_lodader ? api.loader(false) : null;
          if (!error.response.data.errors) {
            // errorManager.showToast(
            //   "Ocurrió un error desconocido, vuelve a intentarlo mas tarde"
            // )
          } else {
            error.api = error.response.data.errors;
          }
          reject(error)

        }
      )
    });

  },
  delete: function (endpoint, show_lodader = false) {

    show_lodader ? api.loader(true) : null;

    return new Promise((resolve, reject) => {
      axios.delete(baseUri + endpoint, config).then(
        response => {
          show_lodader ? api.loader(false) : null;
          resolve(response)
        },
        error => {

          if (error.toString() === "Error: Network Error") {
            // OfflineController(api.delete, arguments, resolve, reject);
            return;
          }

          show_lodader ? api.loader(false) : null;
          if (!error.response.data.errors) {
            // errorManager.showToast(
            //   "Ocurrió un error desconocido, vuelve a intentarlo mas tarde"
            // )
          } else {
            error.api = error.response.data.errors;
          }
          reject(error)
        }
      )
    });

  },
  authorization: function (status, token) {
    if (status) {
      return axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    } else {
      return delete axios.defaults.headers.common['Authorization']
    }
  }
};

export default api;
