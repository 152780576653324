export default {
    state: {
      app_started: false,
      canal: null,
      route: null,
      online: false,
      routerHistory: []
    },
    mutations: {
      setAppStarted(state, status) {
        state.app_started = status
      },
      setRouteData(state, data) {
        state.route = data
      },
      setOnlineStatus(state, status) {
        state.online = status
      },
      setRouterHistory(state, route) {
        state.routerHistory.push(route);
      },
    },
    actions: {},
    getters: {
      getAppStarted(state) {
        return state.app_started
      },
      getRouteData(state) {
        return state.route
      },
      getOnlineStatus(state) {
        return state.online
      },
      getRouterHistory(state) {
        return state.routerHistory;
      },
    },
  }
  