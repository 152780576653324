import Cookies from "js-cookie";
import store from "../store"
import Vue from "vue";
import api from "../api";

class InitController {

  static loginStatus = false;

  static start() {

    InitController.defineAuthStatus();

  }

  static defineAuthStatus() {


    let token = Cookies.get("session_id");

    let infoUser = localStorage.getItem('infoUser');

    if (token && infoUser) {

      Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token

      
      InitController.loginStatus = !!(token && infoUser);

      store.commit(
        "setLoginStatus",
        !!(token && infoUser)
      );


      store.commit(
        "setTokenAuth",
        token ? token : null
      );

      InitController.getUserInfo().then(
        response => {
          let infoUserN = response.data.data;
          store.commit(
            "setInfoUser",
            infoUserN ? infoUserN : null
          );
          
        },
        error => {
          if (error.response.status === 401 || error.response.status === "401") {

            Cookies.remove("session_id");

            let requireAuth = store.getters.getRouteData.requireAuth;

            store.commit(
              "setLoginStatus",
              false
            );

            if (requireAuth) {
              location.reload();
            }

          } else {
            store.commit(
              "setInfoUser",
              infoUser ? JSON.parse(infoUser) : null
            );
          }
          

        }
      );

    }
  }

  static getUserInfo() {
    return api.post("auth/get-user");
  }



}


export default InitController;
