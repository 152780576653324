import Vue from 'vue'
import Vuex from 'vuex'
import auth_module from './auth_module'
import app_module from "./app_module";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: app_module,
    auth: auth_module
  },
})
