import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import api from "./api";
import store from "./store"
import VueRouter from 'vue-router'
import router from "./router";
import detectMobile from "./utils/DetecMobile";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import InitController from "./utils/InitController"
// import VueConfetti from 'vue-confetti'
import bus from "./utils/bus";
import ForceDownload from "./utils/ForceDownload";
import "./assets/css/app.css"

import VueGeolocation from 'vue-browser-geolocation';
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.config.productionTip = false
Vue.prototype.$bus = bus;
Vue.prototype.$api = api;
Vue.prototype.$mobile = detectMobile;
Vue.prototype.$fileDownload = ForceDownload;

Vue.prototype.$getDate = () => {

  let d = new Date();
  let a = d.getFullYear();
  let m = d.getMonth()+1;
  let dy = d.getDate();

  return `${a}_${m}_${dy}`

}

Vue.prototype.$sizeBanner = (imgs) => {
  let wt = window.screen.width;
  let path = api.baseUriFiles + "banners/"
  let size = ""
  let file = null;

  if (wt > 978) {
    file = imgs.find(i => i.size === 'L')
    size = "l";
  } else if (wt < 978 && wt > 650) {
    file = imgs.find(i => i.size === 'M')
    size = "m"
  } else if (wt < 650) {
    file = imgs.find(i => i.size === 'S')
    size = "s"
  }

  return path + (
    file && file.archivo ? file.archivo : `default_${size}.jpg`
  );

};



Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueGeolocation);
Vue.use(VueHtmlToPaper);
//Definir inicio de sesion y otras cosas
InitController.start();

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
