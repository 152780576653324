<template>
    <div class="p-5">
        <b-container>
            <div class="text-center">
                <h1 class="display-2 text-primary">404</h1>
                <h2 class="text-black-50 font-light">Pagina no encontrada</h2>
                <router-link to="/">
                    <button class="btn btn-md mt-4 btn-primary">
                        <i class="fas fa-arrow-left"></i> Volver a home
                    </button>
                </router-link>
            </div>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "NotFound404"
    }
</script>

<style scoped>

</style>
