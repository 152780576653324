import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from './store/index'
import PageNotFound from './components/dashboard/NotFound'
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./components/dashboard/Home'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name:"login",
      component: () => import("@/components/login/Inicio")
    },
    {
        path: '/registro/edit/:folio',
        name: 'registro',
        component: () => import("@/components/dashboard/RegistroEdit"),
        meta: {
          requiresAuth: true
        }
    },
    {
      path: '',
      name: 'not_found',
      component: () => import('./components/dashboard/NotFound'),
      children: [
        {
          path: "*",
          component: PageNotFound
        }
      ],
      meta: {
        requiresAuth: false
      }
    }
  ],
});

// import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  // if (to.name) {
    // Start the route progress bar.
    // NProgress.start(800);
  // }

  store.commit(
    "setRouterHistory",
    from
  );

if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (store.getters.getInfoUser && store.getters.getInfoUser.admin) {
      next();
      return;
    }
    next('/')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit(
      "setRouteData",
      {requireAuth: true}
    );
    if (store.getters.getLoginStatus) {
      next();
      return
    }
    next('/login')
  } else {
    store.commit(
      "setRouteData",
      {requireAuth: false}
    );
    next()
  }

  // next();
});

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done();
// });

export default router;
